@font-face{
    font-family:'Halcom';
    src:url('font/Halcom-Regular.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family:'Halcom';
    src:url('font/Halcom-Bold.woff');
    font-weight: bold;
    font-style: bold;
}

.logo{
    width: 100px;
}


.container{
    height: 100vh;
}

.container-logo{
    height: 5vh;
}

.container-login{
height: 95vh;
}

.container-compras{
    height: 95vh;
    }

h1{
    font-family:'Halcom';
    font-weight: bold;
    font-style: bold;
    color: #54585A;
    font-size: 36px;
}

label{
    font-size: 15px;
    color: #54585A;
    font-weight: bold;
    padding-left:15px;
}

p.bemVindo{
    font-size: 14px;
    color: #54585A;
}

p.dados{
    font-size: 14px;
    color: #54585A;
    font-weight: bold;
    margin-bottom: 8px!important;
    padding-left:15px;
}

.input-login{
    border-radius: 99px;
    border: solid 1px #ccc;
    outline: none;
    style: none;
    width:330px;
    height: 40px;
}

.input-login-pagamento{
    border-radius: 99px;
    border: solid 1px #ccc;
    outline: none;
    style: none;
    height: 40px;
    width: 100%;

}

.input-login:focus{
    border: solid 1px #54585A;
    box-shadow: 0px 0px 5px rgb(201, 201, 201);
}

.button{
    width: 330px;
    height: 40px;
    background-color: #3BE26E;
    color:white;
    border-style: none;
    border-radius: 99px;
    font-weight: bold;
}

button:focus {
    outline: 1px dotted !important;
}

.buttonPagamento{
    height: 40px;
    background-color: #3BE26E;
    color:white;
    border-style: none;
    border-radius: 99px;
    font-weight: bold;
    width: 93%;
}

.titulos-tabela{
    background-color: #F7F7F7;
    font-weight: bold;
    color: #54585A;
}

.border-table{
    border:2px solid #DEE2E6;
    border-radius: 6px;
}

.col-border{
    border-left: 1px solid #DEE2E6;
}
.icon-credit{
    width: 24px;
    color: #54585A;
}

.icon-arrow{
    width: 10px;
}

.container-formas-pagamento-cartao{
    border: 1px solid #DEE2E6;
    border-radius: 6px 6px 0px 0px;
    cursor:pointer;
}

.container-formas-pagamento-boleto{
    border: 1px solid #DEE2E6;
    border-radius: 0px 0px 6px 6px;
    cursor:pointer;
}

.container-formas-pagamento{
    background-color: #F5F5F5;
    cursor:pointer;
}

.container-input-form{
    background-color: #F5F5F5;
    border-radius: 6px;

}

p.pagamento{
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    color: #54585A;
}

button:disabled {
	color: #C0C0C0;
    background: #DCDCDC;
}

.hidden { display:none; }

.brands {
    position: absolute;
    bottom: 210px;
    right: 311px;
    width: 40px;
    height: 30px;
}

.aguarde{
    position: absolute;
    top: 50%;
    left: calc(50%);
}
.divAguarde{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    background-color: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
}

.align-right{
    text-align: right!important;
} 

.error {
    border: 1px solid red;
  }

::-webkit-input-placeholder {
    color: #ced4da;
 }